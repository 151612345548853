<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.startDate"
              :max="filter.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.startTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.endDate"
              :min="filter.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.endTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md-remove">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('shoe_id')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.shoeId"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('settle_status')"
              />
            </b-input-group-prepend>
            <b-form-select
              v-model="filter.settleStatus"
              :options="settleStatusOptions"
            />
          </b-input-group>
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('vip_room')"
              />
            </b-input-group-prepend>
            <b-form-select
              v-model="filter.vipRoom"
              :options="vipRoomOptions"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
          <b-button
            variant="success float-right"
            class="mr-1"
            @click="exportData"
            v-text="$t('export')"
          />
          <b-form-checkbox
            v-model="filter.includeDownline"
            class="float-right"
            style="margin: 8px 15px;"
            value="true"
            unchecked-value="false"
          >
            <span v-text="$t('include_downline')" />
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'partner_name' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'partner_name' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('partner_name')"
                      >
                        <span v-text="$t('partner_name')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'account' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'account' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('account')"
                      >
                        <span v-text="$t('account')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'begin_time' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'begin_time' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('begin_time')"
                      >
                        <span v-text="$t('begin_time')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'vip_room' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'vip_room' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('vip_room')"
                      >
                        <span v-text="$t('vip_room')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        style="display: none;"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'table_id' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'table_id' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('table_id')"
                      >
                        <span v-text="$t('table_id')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'shoe_id' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'shoe_id' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('shoe_id')"
                      >
                        <span v-text="$t('shoe_id')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        style="display: none;"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'game_id' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'game_id' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('game_id')"
                      >
                        <span v-text="$t('game_id')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'bet_type' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'bet_type' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('bet_type')"
                      >
                        <span v-text="$t('bet_type')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'amount' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'amount' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('amount')"
                      >
                        <span v-text="$t('amount')" />
                      </th>
                      <th
                          class="vgt-left-align"
                      >
                        <span v-text="$t('odd')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('kara')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('cruz')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('result')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('win_lose_amount')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'settle_status' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'settle_status' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('settle_status')"
                      >
                        <span v-text="$t('settle_status')" />
                      </th>
                      <th
                        class="vgt-left-align sortable"
                        :class="{
                          'sorting sorting-asc' : ((orderBy.field !== null && orderBy.field === 'end_time' && orderBy.sort === 'ASC') ? true : false),
                          'sorting sorting-desc' : ((orderBy.field !== null && orderBy.field === 'end_time' && orderBy.sort === 'DESC') ? true : false),
                        }"
                        @click="sortBy('end_time')"
                      >
                        <span v-text="$t('end_time')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('rolling')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                    >
                      <!-- Partner Name -->
                      <td class="vgt-left-align">
                        <span v-text="item.partner_name" />
                      </td>
                      <!-- Account -->
                      <td class="vgt-left-align">
                        <a
                          href="#"
                          class="align-middle ml-50"
                          @click="filterByAccount(item.account)"
                          v-text="item.account"
                        />
                      </td>
                      <!-- Begin Time -->
                      <td class="vgt-left-align">
                        <span v-text="item.begin_time" />
                      </td>
                      <!-- VIP Room -->
                      <td class="vgt-left-align">
                        <span v-text="item.vip_room" />
                      </td>
                      <!-- Table ID -->
                      <td
                        class="vgt-left-align"
                        style="display: none;"
                      >
                        <span v-text="item.game_table_id" />
                      </td>
                      <!-- Shoe ID -->
                      <td class="vgt-left-align">
                        <span class="game-table-id">{{ item.game_table_id }}</span>
                        <span class="game-shoe-id">{{ item.game_shoe_id }}</span>
                        <span class="game-round-id">{{ item.game_round_id }}</span>
                      </td>
                      <!-- Game ID -->
                      <td
                        class="vgt-left-align"
                        style="display: none;"
                      >
                        <span v-text="item.game_id" />
                      </td>
                      <!-- Bet Type -->
                      <td class="vgt-left-align">
                        <span
                          v-if="item.game_table_id == 'KC-' && item.bet_type == 'player'"
                          :class="`game-history-${item.bet_type}-type`"
                          v-text="'Kara'"
                        />
                        <span
                          v-else-if="item.game_table_id == 'KC-' && item.bet_type == 'banker'"
                          :class="`game-history-${item.bet_type}-type`"
                          v-text="'Cruz'"
                        />
                        <span
                          v-else
                          :class="`game-history-${item.bet_type}-type`"
                          v-text="item.bet_type.toUpperCase()"
                        />
                      </td>
                      <!-- Amount -->
                      <td class="vgt-left-align">
                        <span
                          class="align-middle ml-50"
                          v-text="item.amount"
                        />
                      </td>
                      <!-- Odd -->
                      <td class="vgt-left-align">
                        <span
                          class="align-middle ml-50"
                          v-text="item.odd"
                        />
                      </td>
                      <!-- Player -->
                      <td
                        class="vgt-left-align"
                        :class="{
                          'winning-card': item.card_result === 'player'
                        }"
                      >
                        <div
                          v-if="item.player_game_card.length > 0 && item.game_table_id != 'KC-'"
                          class="text-center card-result-container"
                          :class="(item.card_result !== 'player') ? 'lose-card' : ''"
                        >
                          <span
                            v-for="(card, index) in item.player_game_card"
                            :key="index"
                            class="card-display"
                          >
                            <img
                              :src="card.icon"
                            >
                            <span v-text="card.display" />
                          </span>
                        </div>
                      </td>
                      <!-- Banker -->
                      <td
                        class="vgt-left-align"
                        :class="{
                          'winning-card': item.card_result === 'banker'
                        }"
                      >
                        <div
                          v-if="item.banker_game_card.length > 0 && item.game_table_id != 'KC-'"
                          class="text-center"
                        >
                          <span
                            v-for="(card, index) in item.banker_game_card"
                            :key="index"
                            class="card-display"
                          >
                            <img
                              :src="card.icon"
                            >
                            <span v-text="card.display" />
                          </span>
                        </div>
                      </td>
                      <!-- Result -->
                      <td class="vgt-center-align">
                        <span
                          v-if="item.game_table_id != 'KC-'"
                          v-text="item.result"
                        />
                        <br v-if="item.game_table_id != 'KC-'">
                        <span
                          :class="`result-${item.win_lose}`"
                          v-text="item.win_lose.toUpperCase()"
                        />
                      </td>
                      <!-- Win Lose Amount -->
                      <td class="vgt-left-align">
                        <span
                          v-if="item.win_lose == 'lose'"
                          class="card-result-banker"
                          v-text="item.win_lose_amount"
                        />
                        <span
                          v-else-if="item.win_lose == 'tie'"
                          class="card-result-tie"
                          v-text="item.win_lose_amount"
                        />
                        <span
                          v-else
                          v-text="item.win_lose_amount"
                        />
                      </td>
                      <!-- Settle Status -->
                      <td class="vgt-left-align">
                        <span
                          :class="`settle-status-${item.settle_status.toLowerCase()}`"
                          v-text="item.settle_status"
                        />
                      </td>
                      <!-- End Time -->
                      <td class="vgt-left-align">
                        <span v-text="item.end_time" />
                      </td>
                      <!-- Rolling -->
                      <td class="vgt-left-align">
                        <span
                          v-text="item.rolling"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BFormSelect,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

import Game from '@/models/Game'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportGameHistory',
  components: {
    BOverlay,
    BCard,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      agentId: null,
      items: [],
      loading: true,
      report: {
        total_bet: 0,
        total_rolling: 0,
        total_win_loss: 0,
      },
      perPage: 10,
      totalPage: 0,
      orderBy: {
        field: typeof this.$route.query.orderByField !== 'undefined' ? this.$route.query.orderByField : 'begin_time',
        sort: typeof this.$route.query.orderBySort !== 'undefined' ? this.$route.query.orderBySort : 'DESC',
      },
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        shoeId: typeof this.$route.query.shoeId !== 'undefined' ? this.$route.query.shoeId : '',
        settleStatus: typeof this.$route.query.settleStatus !== 'undefined' ? this.$route.query.settleStatus : '',
        vipRoom: typeof this.$route.query.vipRoom !== 'undefined' ? this.$route.query.vipRoom : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
        includeDownline: typeof this.$route.query.includeDownline !== 'undefined' ? this.$route.query.includeDownline : 'false',
      },
      currentFilter: {
        page: 1,
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        shoeId: typeof this.$route.query.shoeId !== 'undefined' ? this.$route.query.shoeId : '',
        settleStatus: typeof this.$route.query.settleStatus !== 'undefined' ? this.$route.query.settleStatus : '',
        vipRoom: typeof this.$route.query.vipRoom !== 'undefined' ? this.$route.query.vipRoom : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
        includeDownline: typeof this.$route.query.includeDownline !== 'undefined' ? this.$route.query.includeDownline : 'false',
      },
      settleStatusOptions: [
        { value: '', text: 'All' },
        { value: 'Unsettled', text: 'Unsettled' },
        { value: 'Settled', text: 'Settled' },
      ],
      vipRoomOptions: [
        { value: '', text: 'All' },
        { value: 'Solaire', text: 'Solaire' },
        { value: 'Jupiter', text: 'Jupiter' },
        { value: 'Hilton', text: 'Hilton' },
      ],
    }
  },
  mounted() {
    if (this.userData.role === 'admin' || this.userData.role === 'company') {
      this.filter.includeDownline = 'true'
    }
    this.loadData()
  },
  methods: {
    async exportData(a) {
      a.preventDefault()
      try {
        await Game.downloadGameHistoryList({
          ...this.currentFilter,
          agentId: this.agentId,
          orderByField: this.orderBy.field,
          orderBySort: this.orderBy.sort,
        })
      } catch (e) {
        this.$alertHelper.error()
      }
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      searchParams.orderByField = this.orderBy.field
      searchParams.orderBySort = this.orderBy.sort

      this.$router.replace({ query: searchParams })
      const response = await Game.histories(searchParams)
      if (response.data.status) {
        this.items = response.data.data
        this.report = response.data.meta.report
        this.totalPage = response.data.meta.pagination.total
        this.perPage = response.data.meta.pagination.per_page
        this.agentId = response.data.meta.agent.id
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
    filterByAccount(account) {
      this.filter.account = account
      this.loadData()
    },
    async sortBy(column) {
      if (this.orderBy.field === null || column !== this.orderBy.field) {
        this.orderBy.field = column
        this.orderBy.sort = 'ASC'
      } else if (this.orderBy.sort === 'ASC') {
        this.orderBy.sort = 'DESC'
      } else {
        this.orderBy.sort = 'ASC'
      }
      //
      // this.loading = true
      // const searchParams = {}
      // this.filter.page = 1
      // this.currentFilter.page = 1
      // Object.keys(this.currentFilter).forEach(e => {
      //   if (typeof this.currentFilter[e] === 'number') {
      //     if (this.currentFilter[e] > 0) {
      //       searchParams[e] = this.currentFilter[e]
      //     }
      //   } else if (typeof this.currentFilter[e] === 'string') {
      //     if (this.currentFilter[e] !== null && this.currentFilter[e].length > 0) {
      //       searchParams[e] = this.currentFilter[e]
      //     }
      //   }
      // })
      // searchParams.orderByField = this.orderBy.field
      // searchParams.orderBySort = this.orderBy.sort
      //
      // const response = await Game.list(searchParams)
      // this.items = response.data.data
      // this.totalPage = response.data.meta.pagination.total
      // this.perPage = response.data.meta.pagination.per_page
      // this.report = response.data.meta.report
      //
      // EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      // this.loading = false
    },
  },
}
</script>
